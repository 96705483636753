import { EntityOnboardingPayload } from '@shared/api';

import { EntityOnboardingData } from '../types/EntityOnboarding.types';

export const transformEntityOnboardingPayloadToEntityOnboardingData = (
  data: EntityOnboardingPayload,
): Partial<EntityOnboardingData> => data;

export const transformEntityOnboardingDataToEntityOnboardingPayload = (data: Partial<EntityOnboardingData>) =>
  Object.entries(data).reduce<EntityOnboardingPayload>(
    (acc /*, [_key, value]*/) =>
      // const key = _key as keyof EntityOnboardingData;

      acc,
    {},
  );
